<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/tamogiefectivo/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">MIRASOL COMPANY LIMITED</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">เกี่ยวกับเรา</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">การแนะนำสินเชื่อ</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/question' }" class="Li3">
          <router-link to="/question" class="nav-link3">
            <span class="routeText3">ศูนย์ช่วยเหลือ</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
          <!-- <router-link to="/protocol" class="nav-link4">
            <span class="routeText4">ข้อตกลงความเป็นส่วนตัว</span>
          </router-link> -->
          <a
            href="https://boo.lendboostapp.com/T8d1w3a/T7A3f5Paa.html"
            class="nav-link3"
            target="_blank"
          >
            <span class="routeText4">ข้อตกลงความเป็นส่วนตัว</span>
          </a>
        </li>
      </ul>
    </div>
    <div style="height: 110px"></div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logo: "NAM A ASSET MANAGEMENT COMPANY LIMITED",
      backgroundImage: "../../src/page/jaidee/components/img/Rectangle.png",
      link1Color: "white", // 初始颜色，可以是你希望的颜色
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  position: sticky; /* 将导航栏固定在页面上 */
  top: 0; /* 位于页面顶部 */
  z-index: 1000; /* 确保导航栏在其他元素上方 */
  height: 80px;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #ffffff;
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
  margin-left: 50px;
}

.title {
  width: 460px;
  height: 24px;
  font-size: 16px;
  font-family: Roboto Flex-SemiBold;
  font-weight: 600;
  color: #333333;
  line-height: 23px;
  /* padding-left: 18px; */
  padding-left: 80px;
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: Roboto Flex-Medium;
  /* font-weight: 600; */
  color: #333333;
  line-height: 29px;
}

.routeText1 {
  width: 85px;
  padding-left: 8px;
  padding-right: 8px;
}

.routeText2 {
  width: 148px;
  padding-left: 8px;
  padding-right: 8px;
}

.routeText3 {
  width: 160px;
  padding-left: 8px;
  padding-right: 8px;
}

.routeText4 {
  width: 200px;
  padding-left: 16px;
  padding-right: 8px;
  text-align: center;
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  height: 49px;
  font-weight: 500;
  line-height: 39px;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  padding-top: 5px;
  padding-bottom: 5px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 49px;
  /* border: 1px solid red; */
  text-align: center;
}
.Li1 {
  width: 170px;
}
.Li2 {
  width: 190px;
}
.Li3 {
  width: 170px;
}
.Li4 {
  width: 240px;
}
.lastLi {
  width: 190px;
  margin-right: 28px;
}
.active-link {
  background-size: 100% 100%;
  background-color: #139BEB;
  border-radius: 5px;
}
.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-size: 18px;
  font-family: Inter-Extra Bold;
  font-weight: 600;
  color: #ffffff;
}

.title {
  /* border: 1px solid red; */
  width: 500px;
  padding-left: 20px;
}
</style>
